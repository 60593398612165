<template>
    <div class="page-error">
        <p>{{ message }}</p>
        <router-link to="/">Click to return</router-link>
    </div>
</template>

<script>
export default {
    name: 'error',
    computed: {
        message() {
            return this.$route.params.error || '404';
        }
    },
    created() {
        let query = this.$route.query;
        if (Object.keys(query).length !== 0) {
            this.$router.replace({
                name: 'error',
                params: query
            });
        }
        this.$router.push('/')
    }
};
</script>

<style lang="sass" scoped>
.page-error
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
</style>
